/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import {
  updateInvoiceDetails, setAreInvoiceDetailsLoading, updateInvoice,
} from '../../../redux/slices/invoices';
import PageFooterButtons from '../../../components/shared/PageFooterBtns';
import InvoiceForm from './InvoiceForm';
import LineItemsTable from '../InvoicesTable/LineItemsTable';
import { formatCurrency } from '../utils/invoice.utils';
import InvoiceShipments from './InvoiceShipments';
import { Invoice } from '../../../redux/models/invoice.models';
import Analytics from '../../../utils/analytics';
import { isPermissions } from '../../../redux/slices/settings.utils';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';
import Permission from '../../../components/shared/permissions/Permission';
import { getPermissions } from '../../../config';
import { EntityContainer } from '../../../redux/models/core.models';

const page = 'invoiceDetails';
const WriteInvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

const ReadShipmentPermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Read,
};

export default function InvoiceDetails({ invoiceDetails }: { invoiceDetails: Invoice }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const permissions = getPermissions();

  const isEditable = isPermissions(orgCode, permissions, WriteInvoicePermissions);
  const {
    bill_to: billTo, customer, total, sub_total: subTotal, line_items: lineItems, shipments, currency,
  } = invoiceDetails.data;
  const customerName = customer.data.name;
  const billToClient = billTo.data.name;

  const displayCustomerName = `Customer: ${customerName || ''}`;
  const displayBillTo = `Bill To: ${billToClient || ''}`;
  const displaySubtotal = `${formatCurrency(currency, subTotal) || ''}`;
  const displayTotal = `${formatCurrency(currency, total) || ''} ${currency}`;
  const defss: EntityContainer<{ name: string; amount: number; sub_total: number; }> = {};
  const taxes = lineItems.reduce((store, item) => {
    const updates = {
      ...store
    };
    const taxId = item.data.tax_id || '';
    const itemAmount = item.data.tax_amount || 0;
    const itemName = item.data.tax_name || '';
    const itemRate = item.data.tax_rate || 0;
    const itemSubtotal = item.data.sub_total || 0;
    const existingAmount = store[taxId]?.amount || 0;
    const existingSubtotal = store[taxId]?.sub_total || 0;
    if (taxId) {
      updates[taxId] = {
        'name': `${itemName} @ ${itemRate * 100}%`,
        'sub_total': existingSubtotal + itemSubtotal,
        'amount': existingAmount + itemAmount,
      }
    }
    return updates;
  }, defss);
  console.log(taxes);
  const handleCancel = () => navigate(-1);

  const handleSave = async () => {
    dispatch(setAreInvoiceDetailsLoading(true));
    try {
      await updateInvoice(invoiceDetails);
      const successMessage = 'Invoice was updated successfully.';
      toast(successMessage, { type: 'success' });
      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't update invoice. ${e.message}. Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
      }
      Analytics.capture(e);
    } finally {
      dispatch(setAreInvoiceDetailsLoading(false));
    }
  };
  const handleForm = (prop: string, value: any) => {
    const updates = {
      ...invoiceDetails,
      data: {
        ...invoiceDetails.data,
        [prop]: value,
      },
    };
    dispatch(updateInvoiceDetails(updates));
  };

  return (
    <>
      <Card>
        <Card.Body className="invoice-header">
          <Row className="mt-4">
            <Col sm={8} className={`${page}-billing-info`}>
              <h3>{displayCustomerName}</h3>
              <h3>{displayBillTo}</h3>
            </Col>
            <InvoiceForm invoiceDetails={invoiceDetails} handleForm={handleForm} isEditable={isEditable} page={page} />
          </Row>
        </Card.Body>
        <Card.Body className="invoice-lineItems">
          <LineItemsTable lineItems={lineItems} shipments={shipments} page={page} currency={currency} />
          <div className="flex align-items-end">
            <div />
            <div className="float-end">
              <Table style={{ borderBottom: 'none' }}>
                <tbody>
                  <tr style={{ userSelect: 'none' }}>
                    <td><h4>Subtotal:</h4></td>
                    <td><h4>{displaySubtotal}</h4></td>
                  </tr>
                  {Object.values(taxes).map((tax) => {
                    return (
                      <tr key={tax.name} style={{ userSelect: 'none' }}>
                        <td>{`${tax.name} on ${formatCurrency(currency, tax.sub_total) || ''}`}</td>
                        <td>{`${formatCurrency(currency, tax.amount) || ''}`} </td>
                      </tr>
                    )
                  })}
                  <tr>
                    <td><h3>Total:</h3></td>
                    <td><h3>{displayTotal}</h3></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Permission resources={ReadShipmentPermissions}>
        <InvoiceShipments page={page} shipments={shipments} isEditable={isEditable} />
      </Permission>
      <PageFooterButtons
        handleSave={handleSave}
        handleClose={handleCancel}
        page={page}
        deleteButton={false}
        disabled={false}
        isEditable={isEditable}
      />
    </>
  );
}
