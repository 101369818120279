/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { TripTableColumns } from '../../../../../redux/models/board.models'
import ShipmentCell from '../cells/ShipmentCell';
import { ArrowRightCircle } from 'react-feather';
import { Stack } from 'react-bootstrap';
import { Shipment, ShipmentListView } from '../../../../../redux/models/shipment.models';
import { useSelector } from 'react-redux';
import { otherUpdateShipmentListViewItem, selectShipmentEntity, selectShipmentFilterParams, updateShipmentListViewItem } from '../../../../../redux/slices/shipments';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import { selectBillingItemsObject, selectShipmentBoardSettings, selectShippingLinesObject } from '../../../../../redux/slices/settings';
import { deepEqual } from '../../../../../utils/core.utils';
import { errorToast } from '../../../../../components/notifications/app-toast';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { BillingItem, BillingType } from '../../../../../redux/models/billing.models';
import { handleBilling } from '../../../utils/shipments.utils';
import { ShippingLine } from '../../../../../redux/models/settings.models';

const getPostBody = (
  shipment: Shipment,
  prop: string,
  value: any,
  billingTypes: EntityContainer<BillingType>,
  billingItems: EntityContainer<BillingItem>,
  shippingLines: EntityContainer<ShippingLine>,
) => {
  const updates: any = {
    ...shipment,
    data: {
      ...shipment.data,
      [prop]: value,
    }
  };
  if (shipment.data.billing_type_id && shipment.data.billing_type_id !== '') {
    const typeRel = billingTypes[shipment.data.billing_type_id];
    if (typeRel !== undefined && typeRel !== null) {
      updates.data.billing_type = typeRel;
    }
  }
  if (shipment.data.shipping_line_id && shipment.data.shipping_line_id !== '') {
    const shippingLineRel = shippingLines[shipment.data.shipping_line_id];
    if (shippingLineRel !== undefined && shippingLineRel !== null) {
      updates.data.shipping_line = shippingLineRel;
    }
  }

  if (prop === 'customer_id') {
    console.log(updates.data);
    updates.data.billing_type_id = '';
    updates.data.billing_type = null;
    updates.data.base_rate = null;
    updates.data.accessorials = [];
  };
  if (prop === 'billing_type_id') {
    const billingDetails = handleBilling(value, Object.values(billingTypes), Object.values(billingItems));
    const isEmpty = value === '';
    if (isEmpty) {
      updates.data.billing_type = null;
    } else {
      const type = billingTypes[value];
      if (type !== undefined && type !== null) updates.data.billing_type = type;
    }
    updates.data.base_rate = billingDetails.base_rate;
    updates.data.accessorials = billingDetails.accessorials;
  };
  return updates;
}

interface OtherProps {
  shipment: ShipmentListView;
  data: Shipment | null;
  onUpdate: (shipmentId: string, key: string, value: any) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateTrip: (shipmentId: string) => void;
  selectedShipments: string[];
  columns: TripTableColumns[];
}

function OtherShipmentRow({
  shipment, data, onUpdate, onSelect, columns, selectedShipments, onOpen, onInvoice, onCreateTrip
}: OtherProps) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const navigate = useNavigate();
  const [entity, setEntity] = useState(data);
  const filters = useSelector(selectShipmentFilterParams);
  const boardSettings = useSelector(selectShipmentBoardSettings);
  const shippingLines = useSelector(selectShippingLinesObject);
  const billingItems = useSelector(selectBillingItemsObject);
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const handleUpdate = async (
    shipmentId: string, key: string, value: any
  ) => {
    if (entity) {
      const data = getPostBody(
        entity, key, value, boardSettings.billingTypes, billingItems, shippingLines
      );
      const isEqual = deepEqual(entity, data);
      if (isEqual) {
        return;
      }
      try {
        const response = await otherUpdateShipmentListViewItem(data);
        if (response.status === 200) {
          const newEnt = response.data.data;
          setEntity(newEnt);
          dispatch(updateShipmentListViewItem(newEnt, filters, boardSettings));
        }
        if (response.status >= 400) {
          errorToast('Error updating shipment');
        }
      } catch (error: any) {
        if (error.description) {
          errorToast(`Error updating shipment. ${error.description}`);
        } else {
          errorToast('Error updating shipment.');
        }
      }
    }
  }
  const handleOpen = () => {
    navigate(`/${orgCode}/shipments/${shipment.id}`);
  }
  useEffect(() => {
    setEntity(data);
  }, [data]);
  return (
    <>
      <tr>
        {columns.map((column, idx) => {
          if (idx === 1) {
            return (
              <td
                style={{ width: column.width }}
                key={column.key}
                onMouseEnter={() => setIsMenuShowing(true)}
                onMouseLeave={() => setIsMenuShowing(false)}
              >
                <Stack direction="horizontal" gap={2}>
                  <ShipmentCell
                    key={column.key}
                    column={column}
                    shipment={shipment}
                    selectedShipments={selectedShipments}
                    onSelect={onSelect}
                    onUpdate={handleUpdate}
                    onOpen={onOpen}
                    onInvoice={onInvoice}
                    onCreateTrip={onCreateTrip}
                  />
                  {isMenuShowing && (
                    <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
                      <ArrowRightCircle size={18} />
                    </div>
                  )}
                </Stack>
              </td>
            )
          }
          return (
            <td style={{ width: column.width }} key={column.key}>
              <ShipmentCell
                key={column.key}
                column={column}
                shipment={shipment}
                selectedShipments={selectedShipments}
                onSelect={onSelect}
                onUpdate={handleUpdate}
                onOpen={onOpen}
                onInvoice={onInvoice}
                onCreateTrip={onCreateTrip}
              />
            </td>
          )
        })}
      </tr>
    </>
  )
}

interface Props {
  shipment: ShipmentListView;
  onUpdate: (shipmentId: string, key: string, value: any) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateTrip: (shipmentId: string) => void;
  selectedShipments: string[];
  columns: TripTableColumns[];
}

function ShipmentRow({
  shipment, onUpdate, onSelect, columns, selectedShipments, onOpen, onInvoice, onCreateTrip
}: Props) {
  const entity = useSelector((state: any) => selectShipmentEntity(state, shipment.id));
  return (
    <OtherShipmentRow
      shipment={shipment}
      data={entity}
      onUpdate={onUpdate}
      onSelect={onSelect}
      columns={columns}
      selectedShipments={selectedShipments}
      onOpen={onOpen}
      onInvoice={onInvoice}
      onCreateTrip={onCreateTrip}
    />
  )
}

export default ShipmentRow;
