import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { Invoice, InvoiceFilters, InvoiceListView, LineItem } from '../models/invoice.models';
import { Shipment } from '../models/shipment.models';
import { DateService } from '../../utils/dateService';
import { formatCurrency, formatPercent } from '../../pages/invoices/utils/invoice.utils';
import { EntityContainer } from '../models/core.models';
import { UOM } from '../models/settings.models';
import Analytics from '../../utils/analytics';
import { QueryFilter } from '../models/network.models';
import { getFromDateQuery, getRelQuery, getSearchQuery, getStringQuery, getToDateQuery } from './network.utils';

const getErrorPlaceholder = (verb: string) =>
  `Couldn't ${verb} invoices. Please contact support if the problem persists`;

export enum InvoiceDownloadType {
  Quickbooks = 'quickbooks',
  Sage = 'sage',
}

export const getError = (
  e: unknown,
  details = "Couldn't get invoices",
  verb = 'get'
): React.ReactText => {
  let em;
  if (e instanceof Error) {
    em = e.message;
    Analytics.capture(em);
  }
  const errorMessage = em ?
    `${details}. ${em}. Please contact support if the problem persists.`
    : getErrorPlaceholder(verb);
  return toast(errorMessage, { type: 'error' });
};

const formatDate = (invoiceData: Invoice) => {
  if (invoiceData) {
    const invoiceDate = invoiceData?.data?.date || '';
    return DateService.getDoMMMFormat(invoiceDate);
  }
  return '';
};

const formatUnix = (invoiceData: Invoice) => {
  if (invoiceData) {
    const startDate = invoiceData.data.date || '';
    return DateService.getUnixTime(startDate);
  }
  return 0;
};

const formatShipments = (shipments: Shipment[] = []) => (
  shipments.reduce((shipmentNo: string[], shipment) => [...shipmentNo, shipment.data.shipment_no], [])
);

export const formatInvoices = (
  invoices: Invoice[],
) => [...invoices].map((invoice) => {
  const view: InvoiceListView = {
    id: invoice.entity_id,
    customer: invoice.data.customer.data.name,
    invoice_no: invoice.data?.invoice_no,
    status: invoice.data?.status || '',
    shipment_no: formatShipments(invoice.data.shipments),
    date: formatDate(invoice || {}),
    unix: formatUnix(invoice || {}),
  };
  return view;
}).sort((a, b) => b.unix - a.unix);

export const formatLineItems = (
  lineItems: LineItem[],
  shipments: Shipment[],
  uomObject: EntityContainer<UOM>,
  currency: string,
) => [...lineItems].map((lineItem) => {
  const shipment = shipments.find((ship) => ship.entity_id === lineItem.data.shipment_id);
  return {
    id: lineItem.entity_id,
    name: lineItem.data.description,
    shipment_no: shipment?.data?.shipment_no,
    qty: lineItem.data.quantity,
    unit: uomObject[lineItem.data.uom_id]?.data?.name || '',
    rate: (lineItem.data.type === 'fuel_surcharge')
      ? formatPercent(lineItem.data.price) : formatCurrency(currency, lineItem.data.price),
    total: formatCurrency(currency, lineItem.data.total),
  };
});


const ALL_OPTION = 'all';

export const clientFilterInvoices = (
  list: InvoiceListView[],
  customerParam: string,
  statusParam: string,
  invoiceParam: string
) => list
  .filter((invoice) => (invoice.customer === customerParam || customerParam === ALL_OPTION)
    && (invoice.status === statusParam || statusParam === ALL_OPTION)
    && (invoice.invoice_no === invoiceParam || invoiceParam === ALL_OPTION));


export const getFindInvoiceFilters = (filters: InvoiceFilters) => {
  const { dateFrom, dateTo, invoice, customer, status } = filters;
  console.log(customer);
  const query: QueryFilter[] = [];
  query.push(getFromDateQuery('date', dateFrom));
  query.push(getToDateQuery('date', dateTo));

  if (invoice !== '' && invoice !== 'all') {
    query.push(getSearchQuery('invoice_no', invoice));
  }
  if (customer && customer !== '' && customer !== 'all') {
    query.push(getRelQuery('customer_id', customer));
  }
  if (status !== '' && status !== 'all') {
    query.push(getStringQuery('status', status));
  }
  return query;
};

export const createHeaders = (type: InvoiceDownloadType) => {
  if (type === InvoiceDownloadType.Quickbooks) {
    return [
      'InvoiceNo',
      'Customer',
      'InvoiceDate',
      'DueDate',
      'ItemDescription',
      'ItemQuantity',
      'ItemPrice',
      'ItemSubTotal',
      'ItemTaxCode',
      'ItemTaxAmount',
      'ItemTotal',
    ];
  }
  return [
    'INVOICE_NO',
    'CUSTOMER_ID',
    'POSTING_DATE',
    'CREATED_DATE',
    'DUE_DATE',
    'DESCRIPTION',
    'LINE_NO',
    'ACCT_NO',
    'LOCATION_ID',
    'SUBTOTAL',
    'TAX_AMOUNT',
    'TAX_DETAILID',
    'AMOUNT',
  ];
};

const createLineItemRows = (
  invoice: Invoice, invNo: string, customer: string, date: string, due: string, type: InvoiceDownloadType
) => {
  const items = invoice.data.line_items || [];
  return items.map((item, idx) => {
    if (type === InvoiceDownloadType.Quickbooks) {
      return {
        'InvoiceNo': invNo,
        'Customer': customer,
        'InvoiceDate': date,
        'DueDate': due,
        'ItemDescription': item.data.description || '',
        'ItemQuantity': item.data.quantity,
        'ItemPrice': item.data.price,
        'ItemSubTotal': item.data.sub_total,
        'ItemTaxCode': item.data.tax_name || '',
        'ItemTaxAmount': item.data.tax_amount || 0,
        'ItemTotal': item.data.total,
      };
    }
    return {
      'INVOICE_NO': invNo,
      'CUSTOMER_ID': '',
      'POSTING_DATE': date,
      'CREATED_DATE': date,
      'DUE_DATE': due,
      'DESCRIPTION': item.data.description || '',
      'LINE_NO': idx + 1,
      'ACCT_NO': '',
      'LOCATION_ID': '',
      'SUBTOTAL': item.data.sub_total || 0,
      'TAX_AMOUNT': item.data.tax_amount || 0,
      'TAX_DETAILID': item.data.tax_name || '',
      'AMOUNT': item.data.total,
    };
  });
};

export const createRows = (invoices: Invoice[], type: InvoiceDownloadType) => {
  return invoices.flatMap((inv) => {
    const invNo = inv.data.invoice_no || '';
    const customer =  inv.data.customer?.data.name || '';
    const dateStr =  inv.data.date || '';
    const dueStr = DateService.addDaysToDateISO(dateStr, 30);
    const date = DateService.getYYYYMMDDFormat(dateStr);
    const due = DateService.getYYYYMMDDFormat(dueStr);
    return [
      ...createLineItemRows(inv, invNo, customer, date, due, type),
    ];
  });
};

export const createInvoiceCSVFile = (invoices: Invoice[], type = InvoiceDownloadType.Quickbooks) => {
  const headers = createHeaders(type);
  const rows = createRows(invoices, type);
  const config = {
    quotes: false,
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    newline: '\r\n',
    skipEmptyLines: false,
    columns: headers,
  };
  const entityData = Papa.unparse(rows, config);
  const csvData = new Blob([entityData], { type: 'text/csv;charset=utf-8;' });
  const dataURI = `data:text/csv;charset=utf-8,${headers}\n${rows}`;
  const URL = window.URL || window.webkitURL;
  const item = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(csvData);
  window.open(item);

  console.log(headers);
  console.log(rows);
};
