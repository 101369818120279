export const SHIPMENT_TABLE_HEADERS = [
  {
    name: 'Shipment No.',
    key: 'shipment_no',
  },
  {
    name: 'Customer',
    key: 'customer',
  },
  {
    name: 'Type',
    key: 'type',
  },
];

export const BILLING_SHIPMENT_TABLE_HEADERS = [
  {
    name: 'Date',
    key: 'start_date',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Customer',
    key: 'customer',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Shipment No.',
    key: 'shipment_no',
    visible: true,
    width: 100,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Type',
    key: 'billing_type',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'PO #',
    key: 'purchase_order_no',
    visible: true,
    width: 150,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Container #',
    key: 'container_no',
    visible: true,
    width: 150,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Status',
    key: 'status',
    visible: true,
    width: 100,
    resizable: true,
    entity: 'shipment',
  },
];

export const SHIPMENT_TYPE_TABLE = [
  ...BILLING_SHIPMENT_TABLE_HEADERS,
  {
    name: 'Origin',
    key: 'origin',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Pick Up Time',
    key: 'requested_pick_up_date',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Destination',
    key: 'destination',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
  {
    name: 'Drop Off Time',
    key: 'requested_drop_off_date',
    visible: true,
    width: 200,
    resizable: true,
    entity: 'shipment',
  },
]