/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getGroupsQueryString,
  getTripsQueryString,
  getShipmentQueryString,
  getDestinationIdQueryParam,
} from './aquadashQueryStringBuiler';

export class AquadashServiceConfigProvider {
  static getBoards() {
    return '/board';
  }

  static getTrips(boardId: string, filters: any) {
    return `/trip/list-view?${getTripsQueryString(boardId, filters)}`;
  }

  static getTripDetails(tripId: string) {
    return `/trip/details/${tripId}`;
  }

  static getTripRoute(routeId: string) {
    return `/route/${routeId}`;
  }

  static getTripDocument(documentId: string) {
    return `/route/document/${documentId}`;
  }

  static getGroups(boardId: string) {
    return boardId ? `/group?${getGroupsQueryString(boardId)}` : '/group';
  }

  static syncGroups() {
    return '/group/sync';
  }

  static getGroupDetails(groupId: string) {
    return `/group/${groupId}`;
  }

  static syncDrivers() {
    return '/driver/sync';
  }

  static getDrivers() {
    return '/driver';
  }

  static syncVehicles() {
    return '/vehicle/sync';
  }

  static getAppData() {
    return '/app/data';
  }

  static getVehicles() {
    return '/vehicle';
  }

  static syncFleet() {
    return '/fleet/sync';
  }

  static getFleet() {
    return '/fleet';
  }

  static getFleetLocations(destinationId: string) {
    return destinationId ? `/fleet/locations?${getDestinationIdQueryParam(destinationId)}` : '/fleet/locations';
  }

  static getShipments(filters: any) {
    return `/v2/shipment/list-view?${getShipmentQueryString(filters)}`;
  }

  static getShipmentsByTypeId(filters: any, shipmentTypeId: string) {
    return `/v2/shipment/list-view/shipment-type/${shipmentTypeId}?${getShipmentQueryString(filters)}`;
  }

  static getShipmentsPaginated(filters: any, page: number, pageSize: number) {
    return `/v2/shipment/list-view/paginated?${getShipmentQueryString(filters)}&page=${page}&page_size=${pageSize}`;
  }

  static getShipmentsAllDetails(filters: any) {
    return `/shipment/details?${getShipmentQueryString(filters)}`;
  }

  static getShipmentDetails(shipmentId: string) {
    return `/shipment/details/${shipmentId}`;
  }

  static syncAddresses() {
    return '/address/sync';
  }

  static getAddresses() {
    return '/address';
  }

  static getContacts() {
    return '/contact';
  }

  static syncContacts() {
    return '/contact/sync';
  }

  static getBillingItems() {
    return '/billing-item';
  }

  static syncBillingItems() {
    return '/billing-item/sync';
  }

  static getBillingRules() {
    return '/billing-rule';
  }

  static syncBillingRules() {
    return '/billing-rule/sync';
  }

  static getBillingTypes() {
    return '/billing-type';
  }

  static syncBillingTypes() {
    return '/billing-type/sync';
  }

  static getProducts() {
    return '/product';
  }

  static getTags() {
    return '/tag';
  }

  static getUoms() {
    return '/uom';
  }

  static syncUoms() {
    return '/uom/sync';
  }

  static updateShipment(shipmentId: string) {
    return `/shipment/${shipmentId}`;
  }

  static createShipment() {
    return '/shipment';
  }

  static saveBoard(boardId: string) {
    return `/board/${boardId}`;
  }

  static createDispatchTemplate() {
    return '/note-template';
  }

  static dispatchTemplateEntity(entityId: string) {
    return `/note-template/${entityId}`;
  }

  static createInvoice() {
    return '/invoice/create';
  }

  static getMentions() {
    return '/mention/find';
  }

  static getInvoice(invoiceId: string) {
    return `/invoice/${invoiceId}`;
  }

  static updateInvoice(invoiceId: string) {
    return `/invoice/${invoiceId}`;
  }

  static updateAccessorials(shipmentId: string) {
    return `/invoice/shipment/${shipmentId}`;
  }

  static deleteShipmentInvoice(shipmentId: string) {
    return `/invoice/shipment/${shipmentId}`;
  }

  static createTrip() {
    return '/trip';
  }

  static createTripFromTemplate(templateId: string) {
    return `/trip/create/${templateId}`;
  }

  static createBoardShipment() {
    return '/trip/create';
  }

  static createMove() {
    return '/move';
  }

  static updateTrip(tripId: string) {
    return `/trip/details/${tripId}`;
  }

  static updateBatchMoves() {
    return '/move-batch';
  }

  static sendTripRoute(tripId: string) {
    return `/route/dispatch/${tripId}`;
  }

  static sendTripRouteAsync() {
    return `/route/dispatch/async`;
  }

  static deleteTrip(tripId: string) {
    return `/trip/${tripId}`;
  }

  static deleteShipment(shipmentId: string) {
    return `/shipment/${shipmentId}`;
  }

  static addShipmentsToTrip(tripId: string) {
    return `/trip/shipments/${tripId}`;
  }

  static sendETAEMail() {
    return '/integrations/eta/email';
  }

  static sendFerryEmail() {
    return '/integrations/ferry/email';
  }

  static searchSettings(settingName: string, searchStr: string, apiQueryParam: string) {
    return `/${settingName}/search?${apiQueryParam}=${searchStr}`;
  }

  static getTemplates() {
    return '/trip-template';
  }

  static getNoteTemplates() {
    return '/note-template';
  }

  static deleteTemplate(entityId: string) {
    return `/trip-template/${entityId}`;
  }

  static tripTemplateEntity() {
    return '/trip-template';
  }

  static tripTemplateEntityById(entityId: string) {
    return `/trip-template/${entityId}`;
  }

  static createTemplate() {
    return '/shipment-template/create';
  }

  static uploadFile() {
    return '/file/upload';
  }

  static downloadFile() {
    return '/file/download';
  }

  static deleteFile() {
    return '/file/delete';
  }

  static dispatchTrip(tripId: string) {
    return `/route/dispatch/${tripId}`;
  }

  static updateTripDriver(tripId: string) {
    return `/trip/driver/${tripId}`;
  }
}
