/* eslint-disable import/no-cycle */
import { DataModel, EntityContainer } from './core.models';
import { DateService } from '../../utils/dateService';
import { getFilterValueFromSessionStorage, saveFilterValueToSessionStorage } from '../../utils/core.utils';
import { DropdownOption } from '../../components/shared/dropdown.models';
import { TripListViewData } from './trip.models';
import { Invoice } from './invoice.models';
import { BillingItemData, BillingType } from './billing.models';
import { Document } from './file.models';
import { ShippingLine } from './settings.models';

export interface FormFieldConfig {
  name: string;
  key: string;
  required: boolean;
  type: string;
  visible: boolean;
}

export interface ShipmentTypeFieldData {
  billing_type_id: FormFieldConfig;
  bol: FormFieldConfig;
  booking_no: FormFieldConfig;
  // clearance_no: FormFieldConfig; // PAPS & PARS
  container_no: FormFieldConfig;
  container_size: FormFieldConfig;
  consignee_id: FormFieldConfig;
  customer_id: FormFieldConfig;
  // custom_fields: FormFieldConfig[];
  cut_off_date: FormFieldConfig;
  dangerous_goods: FormFieldConfig;
  // delivery_attachments: FormFieldConfig;
  delivery_order_no: FormFieldConfig;
  destination_id: FormFieldConfig;
  empty_release_depot: FormFieldConfig;
  erd: FormFieldConfig;
  // invoice: FormFieldConfig;
  last_free_date: FormFieldConfig;
  // misc_attachments: FormFieldConfig;
  notes: FormFieldConfig;
  origin_id: FormFieldConfig;
  paps: FormFieldConfig;
  pars: FormFieldConfig;
  // pick_up_attachments: FormFieldConfig;
  port_id: FormFieldConfig;
  product_name: FormFieldConfig;
  purchase_order_no: FormFieldConfig;
  quantity: FormFieldConfig;
  // quantity_uom: FormFieldConfig;
  quantity_uom_id: FormFieldConfig;
  reefer_settings: FormFieldConfig;
  requested_pick_up_date: FormFieldConfig;
  requested_drop_off_date: FormFieldConfig;
  seal_no: FormFieldConfig;
  // shipment_no: FormFieldConfig;
  shipping_line_id: FormFieldConfig;
  // status: FormFieldConfig;
  temp: FormFieldConfig;
  // trip_keys: FormFieldConfig;
  // trips: FormFieldConfig;
  // type: FormFieldConfig;
  vessel: FormFieldConfig;
  weight: FormFieldConfig;
  weight_uom_id: FormFieldConfig;
}

export interface ShipmentTypeData {
  name: string;
  fields: ShipmentTypeFieldData;
  delivery_attachments: Document[];
  misc_attachments: Document[];
  pick_up_attachments: Document[];
};

export type ShipmentType = DataModel<ShipmentTypeData>;

export interface ShipmentListViewData {
  shipment_id?: string;
  billing_type_id: string;
  bol: string;
  container_no: string;
  customer_id: string;
  dangerous_goods: boolean;
  dry: boolean;
  notes: string;
  purchase_order_no?: string;
  reefer_settings?: string;
  shipment_no?: string;
  status: string;
  temp: string;
}

export interface TripBoardShipment {
  id: string;
  billing_type_id: string;
  billingType: string;
  bol: string;
  containerNo: string;
  customer: string;
  customer_id: string;
  dangerous_goods: boolean;
  dry: boolean;
  notes: string;
  purchase_order_no?: string;
  shipment_no: string;
  shipment_status: string;
  temp: string;
}

export interface ShipmentData {
  accessorials: BillingItemData[];
  air_cargo?: boolean;
  ct_pat?: boolean;
  offload_type?: string;
  assignee_id?: string;
  base_rate: BillingItemData;
  billing_type_id: string;
  billing_type?: BillingType;
  booking_no?: string;
  bol: string;
  // clearance_no?: string;
  container_no: string;
  container_size?: string;
  consignee_id?: string;
  customer_id: string;
  cut_off_date?: string;
  dangerous_goods: boolean;
  dates?: {
    end_date: string;
    start_date: string;
  };
  delivery_attachments: Document[];
  delivery_order_no: string;
  destination_id: string;
  dry: boolean;
  empty_release_depot?: string;
  erd?: string;
  invoice?: Invoice;
  last_free_date?: string;
  misc_attachments: Document[];
  notes: string;
  origin_id: string;
  paps?: string;
  pars?: string;
  port_id?: string;
  pick_up_attachments: Document[];
  product_name: string;
  purchase_order_no?: string;
  quantity: number;
  quantity_uom: string;
  quantity_uom_id: string;
  reefer_settings?: string;
  requested_drop_off_date?: string;
  requested_pick_up_date?: string;
  role_ids?: string[];
  seal_no: string;
  shipment_no: string;
  shipping_line_id?: string;
  shipping_line?: ShippingLine;
  status?: string;
  temp: number;
  trip_ids?: string[];
  trips?: TripListViewData[];
  type: string;
  type_id: string;
  vessel?: string;
  weight?: number;
  weight_uom_id?: string;
}

export type Shipment = DataModel<ShipmentData>;

export interface ShipmentListView {
  id: string;
  assignee_id?: string;
  container_no: string;
  customer: string;
  customer_id?: string;
  shipment_no: string;
  billing_type: string;
  billing_type_id?: string;
  type: string;
  type_id: string;
  origin: string;
  origin_id?: string;
  destination: string;
  destination_id: string;
  requested_drop_off_date?: string;
  requested_pick_up_date?: string;
  status: string;
  start_date: string;
  purchase_order_no: string;
  unix: number;
}

const DEFAULT_DATE_RANGE = 'Today';

export const ShipmentDropdownOptions: EntityContainer<DropdownOption> = {
  customer: {
    id: 'customer',
    value: 'Customer'
  },
  startDate: {
    id: 'start_date',
    value: 'Date'
  },
  type: {
    id: 'type',
    value: 'Shipment Type'
  },
  type_id: {
    id: 'type_id',
    value: 'Shipment Type'
  },
  billing_type: {
    id: 'billing_type',
    value: 'Quote Template'
  },
  status: {
    id: 'status',
    value: 'Status'
  },
}

export interface ShipmentListFilters {
  assigneeId: string;
  containerNo: string;
  customer: string;
  dateFrom: string;
  dateTo: string;
  dateRange: string;
  destination: string;
  grouping: string;
  loadType: string;
  origin: string;
  purchaseOrder: string;
  status: string;
  shipmentType: string;
  shipmentTypeId: string;
}

export interface ShipmentGroup {
  id: string;
  name?: string;
  shipments: ShipmentListView[];
}

export const defaultShipmentListFilters: ShipmentListFilters = {
  assigneeId: '',
  containerNo: '',
  dateFrom: DateService.getStartOfTheDayISO(new Date()),
  dateTo: DateService.getEndOfTheDayISO(new Date()),
  dateRange: DEFAULT_DATE_RANGE,
  grouping: '',
  origin: '',
  destination: '',
  customer: '',
  shipmentType: '',
  shipmentTypeId: '',
  loadType: '',
  purchaseOrder: '',
  status: '',
};

export const FILTER_KEY = 'shipment-list-filters';

export const getSessionFilters = (): ShipmentListFilters => {
  const sessionFilterString = getFilterValueFromSessionStorage(FILTER_KEY);
  return sessionFilterString ? JSON.parse(sessionFilterString) : defaultShipmentListFilters;
};

export const saveSessionFilters = (filters: ShipmentListFilters) => {
  saveFilterValueToSessionStorage(FILTER_KEY, filters);
};

export interface ShipmentState {
  areShipmentsLoading: boolean;
  isLoaded: boolean;
  container: EntityContainer<Shipment>,
  groupedShipments: ShipmentGroup[],
  shipments: ShipmentListView[],
  filteredShipments: ShipmentListView[],
  areShipmentDetailsLoading: boolean;
  shipmentDetails: Shipment | null;
  filteredShipmentsAllDetails: Shipment[],
  filterParams: ShipmentListFilters;
}

export const initialState: ShipmentState = {
  areShipmentsLoading: true,
  isLoaded: false,
  container: {},
  groupedShipments: [],
  shipments: [],
  filteredShipments: [],
  areShipmentDetailsLoading: true,
  shipmentDetails: null,
  filteredShipmentsAllDetails: [],
  filterParams: getSessionFilters(),
};

export const resetInitialState = () => ({
  ...initialState,
  isLoaded: true,
  areShipmentsLoading: false,
  filterParams: getSessionFilters(),
});