/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';

import {
  filterInvoiceDates,
  getLineItems,
  loadNextPage,
  selectAreInvoicesLoading,
  selectInvoiceFilterParams,
  selectInvoiceList,
  selectInvoices,
  selectIsListLoaded,
  selectLoadingNextInvoicePage,
} from '../../redux/slices/invoices';

import { LOADING_TEXT } from '../boards/constants/boards.constants';
import Header from './Header';
import InvoiceTable from './InvoicesTable/InvoiceTable';
import NoInvoiceMsg from './InvoicesTable/NoInvoiceMsg';
import { Invoice, LineItem, getSessionFilters, INVOICE_PAGE_SIZE } from '../../redux/models/invoice.models';
import { createInvoiceCSVFile, InvoiceDownloadType } from '../../redux/slices/invoices.utils';
import Analytics from '../../utils/analytics';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import { selectContacts } from '../../redux/slices/settings';
import { createDropdownOptions } from '../../utils/core.utils';
import useAppDispatch from '../../hooks/useAppDispatch';
import { resetShipments } from '../../redux/slices/shipment-list/shipment-list';

const mapInvoices = (invoices: Invoice[], lineItems: LineItem[]) => {
  return invoices.map((invoice) => {
    const items = lineItems.filter((item) => item.data.invoice_id === invoice.entity_id);
    return {
      ...invoice,
      data: {
        ...invoice.data,
        line_items: items,
      }
    }
  })
};

export default function InvoiceList() {
  const dispatch = useAppDispatch();
  const filters = useSelector(selectInvoiceFilterParams);
  const contacts = useSelector(selectContacts);
  const invoices = useSelector(selectInvoices);
  const list = useSelector(selectInvoiceList);
  const loading = useSelector(selectAreInvoicesLoading);
  const isLoaded = useSelector(selectIsListLoaded);
  const loadingNextPage = useSelector(selectLoadingNextInvoicePage);
  const [isDownloading, setIsDownloading] = useState(false);
  const contactOptions = createDropdownOptions(contacts);

  const page = filters.page || 0;
  const totalInvoices = filters.total || 0;
  const showLoadMore = invoices.length < totalInvoices;
  const nextEntity = (INVOICE_PAGE_SIZE * (page + 1)) + 1;
  const lastLoadedEntity = INVOICE_PAGE_SIZE * (page + 2);
  const lastEntity = lastLoadedEntity > totalInvoices ? totalInvoices : lastLoadedEntity;
  const loadMoreTitle = `Load ${nextEntity} - ${lastEntity}`;

  const handleLoadNextPage = async () => {
    dispatch(loadNextPage({ ...filters, page: page + 1 }, invoices));
  };
  
  const handleDownload = async (type: InvoiceDownloadType) => {
    try {
      setIsDownloading(true);
      console.log(invoices);
      const ids = invoices.map((inv) => inv.entity_id);
      const response = await getLineItems(ids);
      if (response.status === 200) {
        const items = response.data.data || [];
        const invs = mapInvoices(invoices, items);
        createInvoiceCSVFile(invs, type);
      }
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setIsDownloading(false);
    }
  }

  useEffect(() => {
    if (!isLoaded) {
      const storedFilters = getSessionFilters();
      dispatch(filterInvoiceDates({ ...storedFilters, page: 0 }));
    }
  }, [isLoaded]);

  useEffect(() => {
    dispatch(resetShipments());
  }, [dispatch]);

  return (
    <>
      <Helmet title="Invoices" />
      <Container fluid className="p-0">
        <Header
          title={`Invoices (${totalInvoices})`}
          invoices={[]}
          contacts={contactOptions}
          isDownloading={isDownloading}
          onDownload={handleDownload}
        />
        {loading && <Loader text={LOADING_TEXT} />}
        {!!invoices.length && !loading ? (
          <div className="mt-4">
            <InvoiceTable invoices={list} />
            {showLoadMore && (
                <AsyncButton
                  title={loadMoreTitle}
                  disabled={loadingNextPage}
                  id="load_more_invoices_btn"
                  dataCy="load_more_invoices_btn"
                  variant="outline-secondary"
                  spinner="secondary"
                  handleClick={handleLoadNextPage}
                />
              )}
          </div>
        ) : (
          <div className="mt-6">
            <NoInvoiceMsg areInvoicesLoading={loading} />
          </div>
        )}
      </Container>
    </>
  );
}
