/* eslint-disable */
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ButtonSpinner from '../../shared/ButtonSpinner';
import PlaceholderValue from './PlaceholderValue';

const EditableCell: React.FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
  const [editing, setEditing] = useState(false)
  const [tempValue, setTempValue] = useState(value)
  const [isSaving, setSaving] = useState(false);
  const handleBlur = async () => {
    setSaving(true);
    setEditing(false)
    await onChange(tempValue)
    setSaving(false);
  }

  if (editing) {
    return (
      <Form.Control
        type="text"
        value={tempValue}
        onChange={(e) => setTempValue(e.target.value)}
        onBlur={handleBlur}
        autoFocus
      />
    )
  }

  return (
    <div onClick={() => setEditing(true)} style={{ cursor: 'text' }}>
      {value || <PlaceholderValue />}
      {isSaving && <ButtonSpinner />}
    </div>
  )
}

export default EditableCell;