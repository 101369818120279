/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { EntityContainer } from "../../../redux/models/core.models";
import {
  FormFieldConfig, Shipment, ShipmentListView, ShipmentTypeFieldData, ShipmentType, ShipmentDropdownOptions
} from "../../../redux/models/shipment.models";
import { getShipmentFields } from "../../../redux/slices/shipments.utils";
import { BillingItem, BillingType } from '../../../redux/models/billing.models';
import { ReeferSetting } from "../constants/shipments.constants";
import { ShippingLine } from "../../../redux/models/settings.models";

export const getUnknownName = (groupBy: string) => {
  const option = Object.values(ShipmentDropdownOptions).find((opt) => {
    return opt.id === groupBy;
  });
  return option?.value || '';
};

export const getType = (groupByKey: string, types: ShipmentType[]) => {
  const type = types.find((entity) => entity.entity_id === groupByKey);
  if (type) {
    return type.data.name || '';
  }
  return null;
};

export const clientFilterShipments = (
  list: ShipmentListView[],
  customerParam: string,
  statusParam: string,
  origin: string,
  destination: string,
  type: string,
  po: string
) => list
  .filter((shipment) => (shipment.customer === customerParam)
    && (shipment.status === statusParam)
    && (shipment.origin === origin)
    && (shipment.type === type)
    && (shipment.destination === destination)
    && (shipment.purchase_order_no === po));

export const removeExtension = (fileName: string) => fileName.substring(0, fileName.lastIndexOf('.')) || fileName;

export const turnIdsToObjects = (array: string[]) => array.map((id) => ({
  entity_id: id,
}));

export const isShipmentValid = (shipment: Shipment, fields: ShipmentTypeFieldData): EntityContainer<boolean> => {
  const data = shipment.data || {};
  const errors = Object.values(fields)
    .filter((field: FormFieldConfig) => field.required)
    .reduce((store: EntityContainer<boolean>, field: FormFieldConfig) => {
      const item: EntityContainer<any> = {
        ...data,
      };
      console.log(`${field.name}: ${item[field.key]}`);
      const valid = item[field.key] !== '' && item[field.key] !== undefined && item[field.key] !== null;
      return {
        ...store,
        [field.key]: valid,
      };
    }, {});
  return errors;
};

export const getShipmentErrorFields = (shipment: Shipment): EntityContainer<boolean> => {
  const type = shipment.data.type || '';
  const fields = getShipmentFields(type);
  const data = shipment.data || {};
  const errors = Object.values(fields)
    .filter((field: FormFieldConfig) => field.required)
    .reduce((store: EntityContainer<boolean>, field: FormFieldConfig) => {
      const item: EntityContainer<any> = {
        ...data,
      };
      console.log(`${field.name}: ${item[field.key]}`);
      const valid = item[field.key] !== '' && item[field.key] !== undefined && item[field.key] !== null;
      return {
        ...store,
        [field.key]: valid,
      };
    }, {});
  return errors;
};

export const getShipmentPageTitle = (shipmentNumber: string) => `Shipment Details • ${shipmentNumber}`;

export const getShipmentType = (typeId: string, types: ShipmentType[]) => {
  return types.find((type) => type.entity_id === typeId);
};

export const getFields = (shipment: Shipment, types: ShipmentType[]) => {
  const remoteType = getShipmentType(shipment.data.type_id, types);
  return remoteType ? remoteType.data.fields : getShipmentFields('');
};

export const isNumberField = (prop: string) => {
  return prop === 'temp' || prop === 'quantity' || prop === 'weight';
};

export const updateShipmentBilling = (
  billingTypeId: string,
  billingTypes: BillingType[],
  billingItems: BillingItem[],
) => {
  const billingType = billingTypes.find((type) => type.entity_id === billingTypeId);
  if (billingType) {
    const baseRateId = billingType.data?.base_rate_id;
    const accessorialIds = billingType.data?.accessorial_ids || [];
    const baseRateBillingItem = billingItems.find((item) => baseRateId === item.entity_id);
    let baseRate = {};
    if (baseRateBillingItem) {
      baseRate = {
        ...baseRateBillingItem.data,
        billing_item_id: baseRateBillingItem.entity_id,
      };
    }

    const accessorials = billingItems.filter(
      (item) => [...accessorialIds].includes(item.entity_id),
    ).map((item) => {
      const formatted = {
        ...item.data,
        billing_item_id: item.entity_id,
      };
      return formatted;
    }) || [];

    return {
      base_rate: baseRate,
      accessorials,
    };
  }
  return {
    base_rate: {},
    accessorials: [],
  };
};

export const handleShipmentFormData = (
  prop: string,
  value: string,
  shipmentDetails: Shipment,
  billingTypes: BillingType[],
  billingItems: BillingItem[],
  shippingLines: EntityContainer<ShippingLine>,
): Shipment => {
  const billingDetails = updateShipmentBilling(value, billingTypes, billingItems);
  const updates: any = {
    ...shipmentDetails,
    data: {
      ...shipmentDetails.data,
      [prop]: isNumberField(prop) ? parseFloat(value) : value,
    },
  };
  if (prop === 'billing_type_id') {
    const isEmpty = value === '';
    if (isEmpty) {
      updates.data.base_rate = null;
      updates.data.accessorials = [];
      updates.data.billing_type = null;
    } else {
      const billingType = billingTypes.find((type) => type.entity_id === value);
      if (billingType) {
        updates.data.billing_type = billingType;
      }
      updates.data.base_rate = billingDetails.base_rate;
      updates.data.accessorials = billingDetails.accessorials;
    }
  }
  if (prop === 'customer_id') {
    updates.data.base_rate = null;
    updates.data.accessorials = [];
    updates.data.billing_type_id = '';
    updates.data.billing_type = null;
  }
  if (prop === 'shipping_line_id') {
    const isEmpty = value === '';
    if (isEmpty) {
      updates.data.shipping_line = null;
    } else {
      updates.data.shipping_line = shippingLines[value] || null;
    }
  }
  return updates;
};

export const mockShipment = () => {
  
}

export const isReeferSettingLegacy = (setting: string | undefined | null, dry: boolean) => {
  const isEmpty = setting === '' || setting === undefined || setting === null;
  return dry && isEmpty;
};

export const getReeferSettingValue = (shipment: Shipment) => {
  if (isReeferSettingLegacy(shipment.data.reefer_settings, shipment.data.dry)) {
    return ReeferSetting.Dry;
  }
  return shipment.data.reefer_settings || '';
};

export const isShipmentTemp = (settings: string | undefined, temp: number | string | undefined, dry: boolean) => {
  const isReeferSetting = settings !== undefined && settings !== '';
  const isTemp = temp !== undefined ||
    isReeferSetting ||
    isReeferSettingLegacy(settings, dry);
  return isTemp;
};

export const handleBilling = (billingTypeId: string, billingTypes: BillingType[], billingItems: BillingItem[]) => {
  const billingType = billingTypes.find((type) => type.entity_id === billingTypeId);
  if (billingType) {
    const baseRateId = billingType.data?.base_rate_id;
    const accessorialIds = billingType.data?.accessorial_ids || [];
    const baseRateBillingItem = billingItems.find((item) => baseRateId === item.entity_id);
    let baseRate = {};
    if (baseRateBillingItem) {
      baseRate = {
        ...baseRateBillingItem.data,
        billing_item_id: baseRateBillingItem.entity_id,
      };
    }

    const accessorials = billingItems.filter(
      (item) => [...accessorialIds].includes(item.entity_id),
    ).map((item) => {
      const formatted = {
        ...item.data,
        billing_item_id: item.entity_id,
      };
      return formatted;
    }) || [];

    return {
      base_rate: baseRate,
      accessorials,
    };
  }
  return {
    base_rate: {},
    accessorials: [],
  };
};

export const validateShipmentData = (
  shipment: Shipment, billingTypes: EntityContainer<BillingType>, shippingLines: EntityContainer<ShippingLine>
): Shipment => {
  const shipUpdates: any = {
    ...shipment,
    data: {
      ...shipment.data
    }
  };
  const isBillingTypeId = shipment.data.billing_type_id !== undefined
    && shipment.data.billing_type_id !== null
    && shipment.data.billing_type_id !== '';
  const isBillingType = shipment.data.billing_type !== undefined && shipment.data.billing_type !== null;
  if (isBillingTypeId === true && isBillingType === false) {
    shipUpdates.data.billing_type = billingTypes[shipment.data.billing_type_id]
  }
  const isShippingLineId = shipment.data.shipping_line_id !== undefined
    && shipment.data.shipping_line_id !== null
    && shipment.data.shipping_line_id !== '';
  const isShippingLine = shipment.data.shipping_line !== undefined && shipment.data.shipping_line !== null;
  if (isShippingLineId === true && isShippingLine === false) {
    shipUpdates.data.shipping_line = shippingLines[shipment.data.shipping_line_id || '']
  }
  return shipUpdates
}
